<template>
  <modal
    :open="!!upsell"
    @closed="closeModal">
    <template
      v-if="upsell"
      #header>
      <h3 class="typeset-3">{{ t("stock-items.upsells.quick-view") }}</h3>
    </template>
    <template
      v-if="upsell"
      #body>
      <div class="-mx-4">
        <div class="sm:px-8 px-4 m-auto pb-4">
          <div
            ref="imageCarousel"
            class="upsell-carousel relative">
            <div
              v-for="image in upsell.carousel_images"
              :key="image.id"
              class="carousel-cell mb-3">
              <img
                :src="image.full"
                :alt="upsell.name"
                class="product-page-carousel-img img-responsive bg-paper" />
            </div>
            <div
              v-if="upsell.quantity && upsell.quantity > 0"
              class="border-2 border-green absolute top-0 left-0 right-0 bottom-3 z-2 pointer-events-none">
              <div
                class="flex items-center text-white bg-green w-full py-2 justify-center">
                <svg-icon
                  name="tick-circle"
                  height="12"
                  width="12"
                  class="mr-3" />
                <p>
                  {{
                    t("add-ons.flash-messages.added-multiple", {
                      count: upsell.quantity
                    })
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="perfectPartners || quickView"
          class="text-center mt-3 mb-6">
          <a
            class="underline"
            :href="upsell.slug">
            {{ t("stock-items.upsells.view-full-link") }}
          </a>
        </div>
        <div class="m-auto px-4">
          <div class="flex justify-between items-start mb-5">
            <div>
              <h2 class="typeset-6 mr-3 flex-no-shrink">{{ upsell.name }}</h2>
              <h2 class="typeset-7 mr-3 flex-no-shrink">
                {{ upsell.product_type_name }}
              </h2>
            </div>
            <div class="flex items-center">
              <h2
                v-if="upsell.price.has_discount"
                class="typeset-7 strikethrough bg-sunflower flex-no-shrink p-2 mr-2">
                {{ upsell.price.base }}
              </h2>
              <h2
                v-if="!upsell.painted_door"
                class="typeset-6 mr-3 flex-no-shrink">
                {{ upsell.price.actual }}
              </h2>
            </div>
          </div>
        </div>
        <h2
          v-if="upsells.length > 1"
          class="typeset-6 mr-3 flex-no-shrink px-4 mt-4">
          {{ t("stock-items.upsells.colours") }}
        </h2>
        <div
          v-if="upsells.length > 1"
          class="grid-cols-7 mb-6 grid gap-3 overflow-x-auto px-4">
          <div
            v-for="(variableUpsell, index) in upsells"
            :key="index">
            <div
              :class="{ 'border border-ink': upsell.id === variableUpsell.id }"
              class="variant-attribute-custom-radio relative cursor-pointer border-ink">
              <div class="aspect-ratio-1x1">
                <div class="block h-full w-full object-contain">
                  <img
                    :src="variableUpsell.image"
                    class="img-responsive"
                    :alt="variableUpsell.name"
                    @click="handleImageClick(index)" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="upsell.highlights"
          class="flex-wrap mb-4 items-end parsed-markdown px-4">
          <h2 class="typeset-6 mr-3 flex-no-shrink mt-4">
            {{ t("product.highlights") }}
          </h2>
          <p
            class="typeset-7"
            v-html="upsell.highlights"></p>
        </div>
        <div class="px-4">
          <slot></slot>
        </div>
      </div>
    </template>
  </modal>
</template>
<script>
import Flickity from "flickity";

import Modal from "../../../shared/Modal.vue";
import SvgIcon from "../../../shared/SvgIcon.vue";

export default {
  name: "UpsellModal",
  components: {
    Modal,
    SvgIcon
  },
  props: {
    upsells: {
      type: Array,
      default: () => []
    },
    upsell: {
      type: Object,
      required: true
    },
    closeModal: {
      type: Function,
      default: () => {}
    },
    setCurrentModalImageIndex: {
      type: Function,
      default: () => {}
    },
    perfectPartners: {
      type: Boolean,
      default: false
    },
    quickView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      flickity: null
    };
  },
  mounted() {
    this.setupCarousel();
  },
  methods: {
    handleImageClick(index) {
      this.setCurrentModalImageIndex(index);
      this.setupCarousel();
    },
    setupCarousel() {
      const carousel = this.$refs.imageCarousel;
      const options = {
        pageDots: true,
        prevNextButtons: true,
        imagesLoaded: true,
        cellSelector: ".carousel-cell",

        // These events work around ios 11.3+ bug
        // https://github.com/metafizzy/flickity/issues/740
        on: {
          dragStart() {
            document.ontouchmove = e => {
              e.preventDefault();
            };
          },
          dragEnd() {
            document.ontouchmove = () => {
              return true;
            };
          }
        }
      };

      if (this.flickity) {
        this.flickity.destroy();
      }

      setTimeout(() => {
        this.flickity = new Flickity(carousel, options);
        this.flickity.resize();
      }, 10);
    }
  }
};
</script>
